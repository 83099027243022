import React, { useState, useRef } from "react";
import { Auth } from 'aws-amplify';
import { validateEmail, validatePassword, validateRepeatPassword } from "../../helper/functions"
import GoogleIcon from "../../assets/userAuth/svg/google-icon.svg";

function Signup({ pageChange, setUserName, setPassword }) {
	const emailInput = useRef("");
	const passInput = useRef("");
	const passRepeatInput = useRef("");
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [passwordRepeatError, setPasswordRepeatError] = useState(false);
	const [signupError, setSignupError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const onSubmitEmailSignup = () => {
		const email = emailInput.current.value;
		const password = passInput.current.value;
		const passwordRepeat = passRepeatInput.current.value;
		let emailValidated = false;
		let passwordValidated = false;
		let passwordRepeatValidated = false;

		setEmailError(false);
		setPasswordError(false);
		setPasswordRepeatError(false);
		setSignupError(false);

		if (validateEmail(email)) {
			emailValidated = true;
		} else {
			setEmailError(true);
			setErrorMsg("Email format is incorrect.");
		}
		if (validatePassword(password)) {
			passwordValidated = true;
		} else {
			setPasswordError(true);
			setErrorMsg("Please choose a password.");
		}
		if (validateRepeatPassword(password, passwordRepeat)) {
			passwordRepeatValidated = true;
		} else {
			setPasswordRepeatError(true);
			setErrorMsg("Passwords do not match.");
		}
		if (emailValidated && passwordValidated && passwordRepeatValidated) {
			Auth.signUp(email, password).then(res => {
				setUserName(res.user.username);
				setPassword(password);
				pageChange("verify");
			}).catch(err => {
				setSignupError(true)
				setErrorMsg(err.message)
			});
		}
	};

	const onSubmitGoogleLogin = () => {
		Auth.federatedSignIn({ provider: 'Google' });
	};

	return (
		<div className="m-auto w-full max-w-xs">
			<form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
				<h1 className="mb-4 text-gray-700 text-center roboto font-bold text-lg">Social Sign Up:</h1>
				<div className="mb-6 flex items-center justify-between">
					<button onClick={onSubmitGoogleLogin} className="shadow inline-flex w-full text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						<img alt="Google" src={GoogleIcon} width="30" className="justify-start fill-current w-4 h-4 mr-2" />
						<span className="w-full text-center">Google Sign Up</span>
					</button>
				</div>
				{/* <div className="mb-6 flex items-center justify-between">
					<button onClick={onSubmitFacebookLogin} className="shadow inline-flex w-full text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						<img src={FacebookIcon} width="30" className="justify-start fill-current w-4 h-4 mr-2" />
						<span className="w-full text-center">Facebook Sign Up</span>
					</button>
				</div> */}
				<hr className="mb-6" />
				<h1 className="text-gray-700 text-center roboto font-bold text-lg">Email Sign Up:</h1>

				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
						Email
      				</label>
					<input ref={emailInput} className={`${emailError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="username" type="text" placeholder="Email" />
				</div>
				<div className="mb-6">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
						Password
      				</label>
					<input ref={passInput} className={`${passwordError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="password" type="password" placeholder="******************" />
				</div>
				<div className="mb-6">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
						Repeat Password
      				</label>
					<input ref={passRepeatInput} className={`${passwordRepeatError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="password" type="password" placeholder="******************" />
					{passwordError || passwordRepeatError || emailError || signupError ? (<p className="text-red-500 text-xs italic">{errorMsg}</p>) : null}
				</div>
				<div className="flex items-center justify-between">
					<button onClick={onSubmitEmailSignup} className="w-32 shadow inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						Sign Up
      				</button>
					<span onClick={() => pageChange("signin")} className="cursor-pointer block align-baseline font-bold text-sm text-indigo-600 hover:text-indigo-800">
						Switch to Sign up
      				</span>

				</div>
			</form>
		</div>
	)
}

export default Signup;