import React from "react";
// import { Link } from "react-router-dom";
// svg imports

function LandingFeatures() {
	return (
		<div className="flex flex-col items-center">
			<div className="infoBoxEven py-12 lg:py-16 md:flex md:justify-center">
				<img
					src="https://aelper-assets.s3-ap-southeast-1.amazonaws.com/images/analysis.png"
					alt=""
					className="w-56 sm:w-64 block mx-auto md:mx-16 xl:mx-40"
				/>
				<div className="w-4/5 mt-2 md:my-auto max-w-xs mx-auto md:mx-16 lg:max-w-lg xl:mx-40 order-first">
					<h3 className="mb-2 md:mb-4 xl:mb-6 roboto font-bold text-lg md:text-2xl lg:text-3xl">
						Visualize link metrics
					</h3>
					<h4 className="openSans text-justify lg:text-lg">
						Share your links using Aelper and finally understand
						what your audience likes through Aelper's total clicks,
						unique clicks, referer, time of day and geolocation data.
					</h4>
				</div>
			</div>
		</div>
	);
}

export default LandingFeatures;
