import React, { useContext } from "react";

import {cleanGivenUrl} from "../helper/functions";
import { AuthContext } from "../App"

function ShareTarget() {
	const { authed } = useContext(AuthContext);

	if (authed) {
		const parsedUrl = new URL(window.location);

		window.addEventListener("DOMContentLoaded", () => {
			let cleanedSharingInfo = cleanGivenUrl(
				parsedUrl.searchParams.get("text")
			);
			if (cleanedSharingInfo === 0) {
				alert("No URL found in the given infomation");
				window.location.replace(window.location.origin);
			} else if (cleanedSharingInfo === 2) {
				alert("More than 1 URL found in the given information");
				window.location.replace(window.location.origin);
			} else {
				window.location.replace(
					window.location.origin +
						"/user/dashboard?text=" +
						cleanedSharingInfo
				);
			}
		});
	} else {
		alert(
			"👋 Register an account to share a link to the app & get your Aelperlink automatically!✨"
		);
		window.location.replace(window.location.origin + "/user/auth");
	}

	return <div></div>;
}

export default ShareTarget;
