import React, { useEffect, useState, createContext } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// Import components
import Navbar from "./components/shared/Navbar";
import LandingPage from "./pages/LandingPage";
import UserAuthPage from "./pages/UserAuthPage";
import UserDashboardPage from "./pages/UserDashboardPage";
import PublicLinkInfoPage from "./pages/PublicLinkInfoPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserGuide from "./pages/UserGuide";
import ShareTarget from "./pages/ShareTarget";
import AnalysticsModal from "./components/shared/AnalyticsModal";
import NotFound from "./pages/NotFound";
import Pricing from "./pages/Pricing";
import "./assets/userDashboard/css/userDashboard.css";
import "./assets/userDashboard/css/modal.css";
import "./assets/shared/css/colors.css";
// Hooks

// SVG Imports
import SvgUserGuide from "./assets/userGuide/svg/SvgUserGuide";
import SvgInstall from "./assets/install/svg/SvgInstall"

import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
// import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

Amplify.configure(awsconfig);

export const ModalContext = createContext(null);
export const AuthContext = createContext(null);

function App() {
	const [selectedModal, setSelectedModal] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [shortenLink, setShortenLink] = useState(null);
	const [authed, setAuthed] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState(null);

	useEffect(() => {
		window.addEventListener("beforeinstallprompt", async (evt) => {
			evt.preventDefault();
			setDeferredPrompt(evt);
		});

		// navigator.serviceWorker.addEventListener('message', event => {
		// 	console.log(event.data.msg);
		// });

		const setAuthHandler = () => {
			Hub.listen('auth', handleAuthChange);
			return () => Hub.remove('auth', handleAuthChange);
		}
		const handleAuthChange = (data) => {
			switch (data.payload.event) {
				case "signIn":
					handleSignedInUser()
					break;
				case "signOut":
					handleSignedOutUser()
					break;
				default:
					console.log("DumDeeDummm...");
			}
		};
		const handleSignedInUser = async () => {
			const user = await Auth.currentAuthenticatedUser();
			if (user) {
				const idToken = user.signInUserSession.idToken.jwtToken;
				localStorage.setItem('id_token', idToken);
				setAuthed(true);
			}
		}
		const handleSignedOutUser = () => {
			localStorage.removeItem("id_token");
			setAuthed(false);
		}
		Auth.currentAuthenticatedUser().then(handleSignedInUser).catch(handleSignedOutUser);
		return setAuthHandler();
	}, []);


	useEffect(() => {
		if (selectedModal || selectedModal === 0) {
			setModalOpen(true);
		} else {
			setModalOpen(false);
		}
	}, [selectedModal]);

	const handleInstall = async () => {
		deferredPrompt.prompt();
		const { outcome } = await deferredPrompt.userChoice;
		if (outcome === "accepted") {
			setDeferredPrompt(null);
		}
	};

	return (
		<Router>
			<AuthContext.Provider value={{ authed, setAuthed }}>
				<ModalContext.Provider value={{ selectedModal, setSelectedModal, shortenLink, setShortenLink }}>
					<div className="">
						{deferredPrompt ?
							(<div onClick={handleInstall} className="fixed install-btn rounded-full text-white bg-indigo-500 cursor-pointer px-4 border-indigo-500 transition duration-300 hover:bg-indigo-600 hover:border-indigo-600 ">
								<div className="flex flex-col justify-center">
									<SvgInstall className="inline-block mx-auto" />
									<span className="inline-block">Install</span>
								</div>
							</div>) : null}
						<Link to="/userGuide">
							<div className="fixed userGuide-btn rounded-full text-white bg-indigo-500 cursor-pointer px-4 border-indigo-500 transition duration-300 hover:bg-indigo-600 hover:border-indigo-600 ">
								<div className="flex flex-col justify-center">
									<SvgUserGuide className="inline-block mx-auto" />
									<span className="inline-block">Guide</span>
								</div>
							</div>
						</Link>

						<Navbar />

						<Switch>
							<Route path="/pricing" exact component={Pricing} />
							<Route
								path="/user/dashboard"
								exact
								// component={UserDashboardPage}
								render={(props) => (
									<UserDashboardPage
										setSelectedModal={setSelectedModal}
									/>
								)}
							/>
							<Route
								path="/"
								exact
								render={(props) => (
									<LandingPage
										setSelectedModal={setSelectedModal}
										setShortenLink={setShortenLink}
									/>
								)}
							/>
							<Route path="/user/auth" exact component={UserAuthPage} />

							<Route
								path="/privacyPolicy"
								exact
								component={PrivacyPolicy}
							/>
							<Route path="/userGuide" exact component={UserGuide} />
							<Route
								path="/share-target"
								exact
								render={(props) => <ShareTarget />}
							/>

							{/* Publin link info route */}
							<Route
								path="/publink/:id"
								render={(props) => <PublicLinkInfoPage props={props} />}
							/>

							<Route path="" component={NotFound} />
						</Switch>

						{modalOpen && (
							<AnalysticsModal />
						)}
					</div>
				</ModalContext.Provider>
			</AuthContext.Provider>
		</Router>
	);

}

export default App;
