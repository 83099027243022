import React, { useState, useEffect, useContext } from "react";

import InputFieldCopy from "./InputFieldCopy"
import SharingBtn from "./SharingBtn"

import { calculateDays } from "../../helper/functions";
import AnalysisDetails from "./AnalysisDetails"
import { get } from "axios";
import { ModalContext, AuthContext } from "../../App"


function AnalyticsModal() {
	const { selectedModal, setSelectedModal, shortenLink } = useContext(ModalContext);
	const { authed } = useContext(AuthContext);
	const [linkStats, setLinkStats] = useState(null)

	const handleClick = (e) => {
		if (e.target.classList.contains("backdrop")) {
			setSelectedModal(null);
		}
	};

	let publicLinkMonitor = ""
	if (shortenLink) {
		publicLinkMonitor = shortenLink.replace("/p/", "/publink/").replace("https://", "");
	}


	useEffect(() => {
		let isMounted = true;
		if (authed && typeof selectedModal === "number") {
			get(`${process.env.REACT_APP_API_URL}/v1/api/link/${selectedModal}`, {
				headers: {
					Authorization: localStorage.getItem("id_token")
				}
			})
				.then(res => {
					if (isMounted) {
						const result = res.data.result;
						result.age = calculateDays(result.age);
						setLinkStats(result);
					}
				});
		}
		return () => isMounted = false;
	}, [authed, selectedModal])

	return (
		<div className="backdrop" onClick={handleClick}>
			{authed ?
				<div id="modalContainer" className="rounded-lg p-8 overflow-y-auto">
					<AnalysisDetails linkInfo={linkStats} />
					<br /><br />
				</div>
				: <div id="publicModalContainer" className="rounded-lg p-2">
					<div className="text-center">
						<InputFieldCopy shortenLink={shortenLink} privateLink={false} />

					</div>
					<div className="text-center mt-1 pl-1rem">
						Monitor at: <a className="w-64 h-40 overflow-y-auto break-words lightBlue pr-6 hover:underline openSans" target="_blank" rel="noopener noreferrer" href={"https://" + publicLinkMonitor}>{publicLinkMonitor}</a>
					</div>

					<div className="pl-4 mt-2">
						<SharingBtn shortenLink={shortenLink} privateLink={false} />
					</div>

				</div>}

		</div>
	);
}

export default AnalyticsModal;
