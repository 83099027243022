import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "axios";
import InputFieldCopy from "../components/shared/InputFieldCopy";
import SharingBtn from "../components/shared/SharingBtn";

import { calculateDays } from "../helper/functions";

import AnalysisDetails from "../components/shared/AnalysisDetails";

function PublicLinkInfoPage({ props }) {
	let publicLinkId = props.match.params.id;
	const [linkInfo, setLinkInfo] = useState(null);

	// Obtain public link info & convert UNIX time to age in days
	useEffect(() => {
		let isMounted = true;
		get(`${process.env.REACT_APP_API_URL}/v1/api/pub-link/${publicLinkId}`)
			.then(res => {
				if (isMounted) {
					const result = res.data.result;
					result.age = calculateDays(result.age);
					setLinkInfo(result);
				}
			});
		return () => isMounted = false;
	}, [publicLinkId]);

	return (
		<div className="backdrop">
			{linkInfo ?
				<div id="modalContainer" className="rounded-lg p-6 sm:p-8 overflow-y-auto">
					<p className="roboto grey-text">
						This link is public and will expire in
					<span className="font-black"> 2 weeks</span>. To create
					persistent links that are private, please
					<Link
							id="publicLinkStatsGoSignIn"
							to="/"
							className="lightBlue hover:underline"
						>
							{" "}
						sign in
					</Link>
					</p>
					<h2 className="roboto   text-2xl mb-4 mt-4">
						Aelperlink
					<hr />
					</h2>
					<div className="">
						<div className="aelperLink">
							<InputFieldCopy shortenLink={linkInfo.aelperLink} privateLink={false} />
							<div className="mt-4"></div>
							<SharingBtn shortenLink={linkInfo.aelperLink} privateLink={false} />
							<div className="mt-4"></div>
							<div className="flex flex-col">
								<p className="roboto">Original Link:&nbsp;&nbsp;</p>
								<a
									className="w-64 break-words lightBlue pr-6 hover:underline openSans"
									target="_blank"
									rel="noopener noreferrer"
									href={linkInfo.t}
								>
									{linkInfo.t}
								</a>
							</div>
						</div>
					</div>
					<h2 className="roboto text-2xl mt-4 mb-4">
						Statistics
					<hr />
					</h2>
					<AnalysisDetails linkInfo={linkInfo} />



					<Link
						id="publicLinkStatsGoBackBtn"
						to="/"
						className="block py-2 px-2 mt-8  rounded text-center ml-auto mr-auto"
					>
						<button className="text-white text-xl font-bold   roboto">
							Go back to home page
					</button>
					</Link>
					<br /><br />
				</div>
				: ""}
		</div>
	);
}

export default PublicLinkInfoPage;
