import React from "react";

import "../assets/userGuide/userGuide.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.css";

// SVG imports
import SVGGuideCover from "../assets/userGuide/svg/guide-cover.svg";
import SVGStep1 from "../assets/userGuide/svg/step-1.svg";
import SVGStep2 from "../assets/userGuide/svg/step-2.svg";
import SVGStep3 from "../assets/userGuide/svg/step-3.svg";
import SVGStep4 from "../assets/userGuide/svg/step-4.svg";
import SVGStep5 from "../assets/userGuide/svg/step-5.svg";
import SVGStep6 from "../assets/userGuide/svg/step-6.svg";
import SVGStep7 from "../assets/userGuide/svg/step-7.svg";
import SVGStep8 from "../assets/userGuide/svg/step-8.svg";

SwiperCore.use([Pagination]);
function UserGuide() {
	return (
		<div className="userGuide-container block pb-24">
			<Swiper
				className="carousel-height"
				pagination={{ clickable: true }}
				spaceBetween={10}
				// slidesPerView={3}
				// onSlideChange={() => console.log("slide change")}
				// onSwiper={(swiper) => console.log(swiper)}
			>
				<SwiperSlide>
					<div className="flex flex-col mt-24 sm:justify-center items-center h-full  sm:mt-8 sm:my-auto">
						<img
							src={SVGGuideCover}
							alt=""
							className="w-56   block mx-auto  "
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 ">
							<h2 className=" roboto   my-4  text-center text-5xl  ">
								Aelper Helper
							</h2>
							<p className="carousel-slide-content-width  openSans font-semibold text-justify w-64  mx-auto sm:text-lg lg:text-lg md:w-64  lg:w-72">
								Everything you need to know about using Aelper.
							</p>
						</div>
					</div>

				</SwiperSlide>
				<SwiperSlide>
					<div className="flex flex-col mt-8 sm:flex-row sm:justify-center sm:items-center sm:h-full sm:mt-8 sm:my-auto">
						<h2 className="text-center roboto font-bold text-2xl sm:hidden">
							Get to Aelper
						</h2>

						<img
							src={SVGStep1}
							alt=""
							className="w-64  sm:w-64  slideImage-size-lg block mx-auto sm:mx-8 md:mx-16 lg:mx-32"
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 sm:my-auto">
							<h2 className=" roboto font-bold text-2xl mb-4 hidden sm:block md:text-3xl ">
								Get to Aelper
							</h2>
							<p className="carousel-slide-content-width  openSans text-justify w-64  mx-auto sm:text-lg lg:text-lg md:w-64  lg:w-72">
								On your device, visit Aelper.
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="flex flex-col mt-8 sm:flex-row sm:justify-center sm:items-center sm:h-full sm:mt-8 sm:my-auto">
						<h2 className="text-center roboto font-bold text-2xl sm:hidden">
							Register/Login
						</h2>

						<img
							src={SVGStep2}
							alt=""
							className="w-64  sm:w-64  slideImage-size-lg block mx-auto sm:mx-8 md:mx-16 lg:mx-32"
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 sm:my-auto">
							<h2 className=" roboto font-bold text-2xl mb-4 hidden sm:block md:text-3xl ">
								Register/Login
							</h2>
							<p className="carousel-slide-content-width  openSans text-justify w-64  mx-auto sm:text-lg lg:text-lg md:w-64  lg:w-72">
								Use any of the methods shown to sign up or log
								in to Aelper.
								<span className="block openSans italic text-sm mt-2">
									Not ready to commit? That’s okay! You can
									still use Aelper without an account, but
									you’ll miss out on cool stuff like permanent
									Aelperlinks and a convenient dashboard.
								</span>
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="flex flex-col mt-8 sm:flex-row sm:justify-center sm:items-center sm:h-full sm:mt-8 sm:my-auto">
						<h2 className="text-center roboto font-bold text-2xl sm:hidden">
							Generating your Aelperlink
						</h2>

						<img
							src={SVGStep3}
							alt=""
							className="w-64  sm:w-64  slideImage-size-lg block mx-auto sm:mx-8 md:mx-16 lg:mx-32"
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 sm:my-auto">
							<h2 className=" roboto font-bold text-2xl mb-4 hidden sm:block md:text-3xl ">
								Generating your Aelperlink
							</h2>
							<p className="carousel-slide-content-width  openSans text-justify w-64  mx-auto sm:mx-0 sm:text-lg lg:text-lg md:w-64  lg:w-72">
								Place your link in the input field and hit
								“shorten”. If your link is copied to your
								clipboard, you can also just hit “shorten” to
								directly create the Aelperlink without pasting
								into the field.
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="flex flex-col mt-8 sm:flex-row sm:justify-center sm:items-center sm:h-full sm:mt-8 sm:my-auto">
						<h2 className="text-center roboto font-bold text-2xl sm:hidden">
							Sharing your Aelperlink
						</h2>

						<img
							src={SVGStep4}
							alt=""
							className="w-64  sm:w-64  slideImage-size-lg block mx-auto sm:mx-8 md:mx-16 lg:mx-32"
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 sm:my-auto">
							<h2 className=" roboto font-bold text-2xl mb-4 hidden sm:block md:text-3xl ">
								Sharing your Aelperlink
							</h2>
							<p className="carousel-slide-content-width  openSans text-justify w-64  mx-auto sm:text-lg lg:text-lg md:w-64  lg:w-72">
								Copy your new Aelperlink or use our handy share
								buttons to send it directly to others. Without
								an account, the Aelperlink expires after 2
								weeks.
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="flex flex-col mt-8 sm:flex-row sm:justify-center sm:items-center sm:h-full sm:mt-8 sm:my-auto">
						<h2 className="text-center roboto font-bold text-2xl sm:hidden">
							Tracking Metrics
						</h2>

						<img
							src={SVGStep5}
							alt=""
							className="w-64  sm:w-64  slideImage-size-lg block mx-auto sm:mx-8 md:mx-16 lg:mx-32"
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 sm:my-auto">
							<h2 className=" roboto font-bold text-2xl mb-4 hidden sm:block md:text-3xl ">
								Tracking Metrics
							</h2>
							<p className="carousel-slide-content-width  openSans text-justify w-64  mx-auto sm:text-lg lg:text-lg md:w-64  lg:w-72">
								Use the publink to access the link statistics.
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="flex flex-col mt-8 sm:flex-row sm:justify-center sm:items-center sm:h-full sm:mt-8 sm:my-auto">
						<h2 className="text-center roboto font-bold text-2xl sm:hidden">
							Using the Dashboard
						</h2>

						<img
							src={SVGStep6}
							alt=""
							className="w-64  sm:w-64  slideImage-size-lg block mx-auto sm:mx-8 md:mx-16 lg:mx-32"
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 sm:my-auto">
							<h2 className=" roboto font-bold text-2xl mb-4 hidden sm:block md:text-3xl ">
								Using the Dashboard
							</h2>
							<p className="carousel-slide-content-width  openSans text-justify w-64  mx-auto sm:text-lg lg:text-lg md:w-64  lg:w-72">
								With an account, you can quickly find the data
								for your different Aelperlinks via the
								dashboard.
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="flex flex-col mt-8 sm:flex-row sm:justify-center sm:items-center sm:h-full sm:mt-8 sm:my-auto">
						<h2 className="text-center roboto font-bold text-2xl sm:hidden">
							Install Aelper on your Device
						</h2>

						<img
							src={SVGStep7}
							alt=""
							className="w-64  sm:w-64  slideImage-size-lg block mx-auto sm:mx-8 md:mx-16 lg:mx-32"
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 sm:my-auto">
							<h2 className=" roboto font-bold text-2xl mb-4 hidden sm:block md:text-3xl ">
								Install Aelper on your Device
							</h2>
							<p className="carousel-slide-content-width  openSans text-justify w-64  mx-auto sm:text-lg sm:mx-0 lg:text-lg md:w-64  lg:w-72">
								Download our web application for an even more
								seamless experience.
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="flex flex-col mt-8 sm:flex-row sm:justify-center sm:items-center sm:h-full sm:mt-8 sm:my-auto">
						<h2 className="text-center roboto font-bold text-2xl sm:hidden">
							Statistics Help
						</h2>

						<img
							src={SVGStep8}
							alt=""
							className="w-64  sm:w-64  slideImage-size-lg block mx-auto sm:mx-8 md:mx-16 lg:mx-32"
						/>
						<div className="sm:mx-8 md:mx-16 lg:mx-32 sm:my-auto">
							<h2 className=" roboto font-bold text-2xl mb-4 hidden sm:block md:text-3xl ">
								Statistics Help
							</h2>
							<p className="carousel-slide-content-width  openSans text-justify w-64  mx-auto sm:text-lg lg:text-lg md:w-64  lg:w-72">
								<span className="block ">
									Total clicks: total number of people who
									clicked on your Aelperlink.
								</span>
								<span className="block mt-2">
									Unique clicks: total number of people who
									clicked on your Aelperlink for the first
									time.
								</span>
								<span className="block mt-2">
									Geolocation: countries the clicks came from.
								</span>
							</p>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
}

export default UserGuide;
