import React, { useRef, useState } from "react";
import { Auth } from 'aws-amplify';
import { validateCode } from "../../helper/functions"

function VerifyEmail({ pageChange, userName, password }) {

	const codeInput = useRef("");
	const [codeError, setCodeError] = useState(false);
	const [verifyError, setVerifyError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const onSubmitCodeVerify = () => {
		const code = codeInput.current.value;
		let codeValidated = false;
		setCodeError(false);
		setVerifyError(false);
		if (validateCode(code)) {
			codeValidated = true;
		} else {
			setCodeError(true);
			setErrorMsg("Please key in verification code.");
		}
		if (codeValidated) {
			Auth.confirmSignUp(userName, code).then(() => {
				Auth.signIn(userName, password).catch(err => {
					setVerifyError(true)
					setErrorMsg(err.message)
				});
			}).catch(err => {
				setVerifyError(true)
				setErrorMsg(err.message)
			});
		}
	};

	return (
		<div className="m-auto w-full max-w-xs">
			<form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="verificationcode">
						Verification code
      				</label>
					<input ref={codeInput} className={`${codeError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="verificationcode" type="text" placeholder="Verification code" />
					{codeError || verifyError ? (<p className="text-red-500 text-xs italic">{errorMsg}</p>) : null}				</div>
				<div className="block flex items-center justify-between">
					<button onClick={onSubmitCodeVerify} className="w-32 shadow inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						Verify
      				</button>
					<div className="block">
						<span onClick={() => pageChange("signin")} className="cursor-pointer block align-baseline font-bold text-sm text-indigo-600 hover:text-indigo-800">
							Switch to Sign in
      				</span>
					</div>
				</div>
			</form>
		</div >
	)
}

export default VerifyEmail;