import React, { useEffect, useState, createContext, useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import OriginalUrlForm from "../components/shared/OriginalUrlForm";
import LinkInfoBox from "../components/userDashboard/LinkInfoBox";

import loadingIcon from "../assets/shared/svg/loading.svg";

import { get } from "axios";
import { base10to64 } from "../helper/functions";
import { AuthContext } from "../App"

export const LinksContext = createContext(null);

function UserDashboardPage({ setSelectedModal }) {
	const [linkArray, setLinkArray] = useState([]);
	const [loading, setLoading] = useState(true);
	const [refresh, setRefresh] = useState(false);
	// const [lKeys, setLKeys] = useState([]);
	const [loadingStatus, setLoadingStatus] = useState(true);

	// For PWA Links from another apps
	const [givenUrl, setGivenUrl] = useState(null);
	const [givenUrlUsed, setGivenUrlUsed] = useState(0);
	const [userKey, setUserKey] = useState(null);

	const { authed } = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;
		if (authed) {
			get(`${process.env.REACT_APP_API_URL}/v1/api/get-all-links-targets`, {
				headers: {
					Authorization: localStorage.getItem("id_token")
				}
			})
				.then(res => {
					if (isMounted) {
						setLinkArray(res.data.result);
						setLoadingStatus(false);
					}
				});
			//Get userKey
			get(`${process.env.REACT_APP_API_URL}/v1/api/user-key`, {
				headers: {
					Authorization: localStorage.getItem("id_token")
				}
			})
				.then(res => {
					if (isMounted) {
						setUserKey(base10to64(res.data.result));
					}
				});
		}
		return () => { isMounted = false };
	}, [refresh, authed]);

	useEffect(() => {
		let isMounted = true;
		const parsedUrl = new URL(window.location);
		window.addEventListener("DOMContentLoaded", () => {
			if (isMounted) {
				setGivenUrl(parsedUrl.searchParams.get("text"));
			}
		});
		return () => isMounted = false;
	}, []);


	// Handle event when user shares a link from 3rd party apps
	useEffect(() => {
		if (authed) {
			if (!loadingStatus) {
				setLoading(false);
				if (givenUrl != null && givenUrlUsed < 1) {
					setGivenUrlUsed(1);
				} else {
					setGivenUrlUsed(1);
				}
			} else {
				setLoading(true);
			}
		}
	}, [authed, givenUrl, refresh, givenUrlUsed, loadingStatus]);

	return (
		<Route
			render={() => {
				if (authed) {
					return (
						<div className="mt-12 mb-4">
							<LinksContext.Provider>
								{loading ? (
									<img
										src={loadingIcon}
										alt="loading"
										className="block mx-auto mt-20"
									/>
								) : (
									<OriginalUrlForm
										newLinkKey={linkArray.length}
										setRefresh={setRefresh}
										refresh={refresh}
									/>
								)}
								<div id="linkInfoBoxList" className="">
									{linkArray
										.slice()
										.reverse()
										.map((linkInfo) => (
											<LinkInfoBox
												key={linkInfo.lk + 1}
												linkInfo={linkInfo}
												userKey={userKey}
												setSelectedModal={setSelectedModal}
											/>
										))}
								</div>
							</LinksContext.Provider>
						</div>
					);
				} else {
					return (
						<Redirect
							to={{
								pathname: "/",
							}}
						/>
					);
				}
			}}
		/>
	);
}

export default UserDashboardPage;
