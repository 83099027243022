import * as React from "react";
import { motion } from "framer-motion";

const svgVariants = {
	hidden: {
		scale: 0,
		opacity: 0,
	},
	visible: {
		scale: 1,
		opacity: 1,
		transition: {
			delay:1,
			type: "spring",
			stiffness: 260,
			damping: 20,
		},
	},
};
const pathVariants = {
	hidden: {
		opacity: 0,
		pathLength: 0,
	},
	visible: {
		opacity: 1,
		pathLength: 1,
		transition: {
			delay: 1.5,
			duration: 3,
			ease: "easeInOut",
		},
	},
};
function SvgSiteLogo(props) {
	return (
		<motion.svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 332.74 165.33"
			{...props}
			variants={svgVariants}
			initial="hidden"
			animate="visible"
		>
			<defs>
				<linearGradient
					id="siteLogo_svg__b"
					x1={41.6}
					y1={3.65}
					x2={41.6}
					y2={167.68}
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#b5b8ff" />
					<stop offset={1} stopColor="#5157ff" />
				</linearGradient>
				<linearGradient
					id="siteLogo_svg__a"
					x1={109.7}
					y1={42.69}
					x2={109.7}
					y2={99.59}
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0.39} stopColor="#5157ff" />
					<stop offset={1} stopColor="#001c7a" />
				</linearGradient>
				<linearGradient
					id="siteLogo_svg__c"
					x1={109.7}
					y1={42.69}
					x2={109.7}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__d"
					x1={160.87}
					y1={42.69}
					x2={160.87}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__e"
					x1={160.87}
					y1={42.69}
					x2={160.87}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__f"
					x1={210.17}
					y1={42.69}
					x2={210.17}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__g"
					x1={210.17}
					y1={42.69}
					x2={210.17}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__h"
					x1={260.05}
					y1={42.69}
					x2={260.05}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__i"
					x1={260.05}
					y1={42.69}
					x2={260.05}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__j"
					x1={313.48}
					y1={42.69}
					x2={313.48}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__k"
					x1={313.48}
					y1={42.69}
					x2={313.48}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
				<linearGradient
					id="siteLogo_svg__l"
					x1={41.95}
					y1={42.69}
					x2={41.95}
					y2={99.59}
					xlinkHref="#siteLogo_svg__a"
				/>
			</defs>
			<g data-name="Layer 2">
				<g data-name="Layer 1">
					<motion.path
						d="M49.87 165.33a33 33 0 01-31.11-22.93 3.81 3.81 0 017.24-2.31 25.33 25.33 0 0023.84 17.61 25.17 25.17 0 0024.91-23.39l.76-57.19c.21-12.2-5.23-23.53-13.87-28.86a3.81 3.81 0 114-6.48c10.95 6.72 17.81 20.64 17.55 35.46l-.77 57.49a32.82 32.82 0 01-32.51 30.6zM32.3 127.82h-1C13.7 127.2-.36 109.38 0 88.09L.78 30.6A32.82 32.82 0 0133.29 0a33 33 0 0131.15 22.92 3.81 3.81 0 01-7.26 2.31A25.33 25.33 0 0033.33 7.62 25.18 25.18 0 008.39 31l-.76 57.2c-.3 17.18 10.46 31.52 24 32a24.46 24.46 0 0017.87-7.57 3.81 3.81 0 115.36 5.37 32.28 32.28 0 01-22.56 9.82z"
						fill="url(#siteLogo_svg__b)"
						variants={pathVariants}
					/>
					<motion.path
						d="M94.2 46.5a1.85 1.85 0 011.8-1.82h27.35a1.8 1.8 0 011.82 1.82 1.82 1.82 0 01-1.82 1.9H97.92v21h22.26a1.86 1.86 0 010 3.72H97.92v21h25.45A1.82 1.82 0 01125.2 96a1.8 1.8 0 01-1.82 1.82H95.87A1.82 1.82 0 0194.2 96z"
						fill="url(#siteLogo_svg__a)"
						variants={pathVariants}
					/>
					<path
						d="M123.37 99.76H95.71A3.71 3.71 0 0192.3 96V46.5a3.73 3.73 0 013.7-3.73h27.35a3.69 3.69 0 013.73 3.73 3.73 3.73 0 01-3.73 3.8H99.83v17.24h20.35a3.77 3.77 0 013.73 3.8 3.73 3.73 0 01-3.73 3.73H99.83v17.16h23.54A3.73 3.73 0 01127.1 96a3.69 3.69 0 01-3.73 3.76z"
						fill="url(#siteLogo_svg__c)"
					/>
					<path
						d="M147.31 97.85h-.15a1.85 1.85 0 01-1.83-1.85V46.5a1.86 1.86 0 013.72 0v47.63h25.45a1.86 1.86 0 110 3.72z"
						fill="url(#siteLogo_svg__d)"
					/>
					<path
						d="M174.5 99.76h-27.35a3.73 3.73 0 01-3.72-3.76V46.5a3.73 3.73 0 013.73-3.73A3.77 3.77 0 01151 46.5v45.73h23.5a3.77 3.77 0 110 7.53z"
						fill="url(#siteLogo_svg__e)"
					/>
					<path
						d="M194.18 46.5a1.85 1.85 0 011.82-1.82h15A15.16 15.16 0 01211 75l-13.14.15V96a1.86 1.86 0 01-3.72 0zM211 71.42a11.51 11.51 0 000-23h-13.1v23z"
						fill="url(#siteLogo_svg__f)"
					/>
					<path
						d="M196 99.76a3.73 3.73 0 01-3.72-3.76V46.5a3.73 3.73 0 013.72-3.73h15a17.06 17.06 0 010 34.12l-11.19.11v19a3.77 3.77 0 01-3.81 3.76zm3.8-30.25H211a9.71 9.71 0 009.58-9.64 9.58 9.58 0 00-9.58-9.56h-11.19z"
						fill="url(#siteLogo_svg__g)"
					/>
					<path
						d="M244.55 46.5a1.85 1.85 0 011.82-1.82h27.35a1.8 1.8 0 011.82 1.82 1.82 1.82 0 01-1.82 1.9h-25.45v21h22.26a1.86 1.86 0 010 3.72h-22.26v21h25.45a1.82 1.82 0 011.82 1.9 1.8 1.8 0 01-1.82 1.82h-27.5a1.82 1.82 0 01-1.67-1.84z"
						fill="url(#siteLogo_svg__h)"
					/>
					<path
						d="M273.72 99.76h-27.66a3.71 3.71 0 01-3.42-3.76V46.5a3.73 3.73 0 013.73-3.73h27.35a3.69 3.69 0 013.73 3.73 3.73 3.73 0 01-3.73 3.8h-23.54v17.24h20.35a3.77 3.77 0 013.73 3.8 3.73 3.73 0 01-3.73 3.73h-20.35v17.16h23.54a3.73 3.73 0 013.73 3.8 3.69 3.69 0 01-3.73 3.73z"
						fill="url(#siteLogo_svg__i)"
					/>
					<path
						d="M323.63 85.85a9.59 9.59 0 00-4.33-8.05 17 17 0 00-6.15-2.66c-.23-.08-.3-.15-.38-.15h-12.91V96a1.86 1.86 0 01-3.72 0V46.5a1.88 1.88 0 011.67-1.82H313a15.19 15.19 0 016.38 28.94 7 7 0 00.76.38 13.45 13.45 0 017.14 11.85A20.43 20.43 0 00328 92a2.79 2.79 0 00.76 1.29 2 2 0 012.05 1.22 2.07 2.07 0 01-1.22 2.43 8 8 0 01-.91.08h-.15a4.24 4.24 0 01-3.42-2.43c-.95-1.75-1.48-4.26-1.48-8.74zM313 48.4h-13.14v22.94h13.44a11.47 11.47 0 00-.3-22.94z"
						fill="url(#siteLogo_svg__j)"
					/>
					<path
						d="M298 99.76a3.73 3.73 0 01-3.77-3.76V46.5a3.8 3.8 0 013.42-3.72H313a17.09 17.09 0 0110 30.87 15 15 0 016.15 12.2 18.53 18.53 0 00.59 5.55v.08a3.85 3.85 0 012.77 2.38l.07.22a4 4 0 01-2.39 4.64l-.33.13h-.36a4.84 4.84 0 01-.91.07 6.13 6.13 0 01-5.27-3.48c-1.13-2.17-1.64-5.14-1.64-9.59 0-2.68-1.1-4.69-3.56-6.52a15.1 15.1 0 00-5.26-2.33l-.31-.08-.15-.05h-10.64V96a3.77 3.77 0 01-3.76 3.76zm15.69-26.46a18 18 0 013.48 1.14l-1.66-1.11 1.49-.7a13.07 13.07 0 01-3.45.61zm-11.93-3.87h11.54a9.57 9.57 0 00-.3-19.13h-11.24z"
						fill="url(#siteLogo_svg__k)"
					/>
					<path
						d="M21.62 101.66a3.81 3.81 0 01-3.78-3.84l.13-15.46V82v-3.33c1.19-24.21 15.2-41.47 33.23-41.16A27.48 27.48 0 0164.11 41a3.81 3.81 0 01-3.67 6.68 19.93 19.93 0 00-9.36-2.51C37.42 45 26.77 58.83 25.62 78.29l32.05.43.06-9.72a3.81 3.81 0 013.81-3.79A3.81 3.81 0 0165.35 69l-.17 28.88a3.81 3.81 0 01-3.81 3.79 3.81 3.81 0 01-3.79-3.83l.07-11.5-32.1-.43-.1 12a3.81 3.81 0 01-3.83 3.75z"
						fill="url(#siteLogo_svg__l)"
					/>
				</g>
			</g>
		</motion.svg>
	);
}

export default SvgSiteLogo;
