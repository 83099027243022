import React, { useRef, useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { validateCode, validateEmail, validatePassword, validateRepeatPassword } from "../../helper/functions"


function ForgetPassword({ pageChange, userName }) {

	const codeInput = useRef("");
	const emailInput = useRef("");
	const passInput = useRef("");
	const passRepeatInput = useRef("");
	const [email, setEmail] = useState(userName);
	const [passwordRepeatError, setPasswordRepeatError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [codeError, setCodeError] = useState(false);
	const [changeError, setChangeError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [firstStage, setFirstStage] = useState(true);

	useEffect(() => {
		emailInput.current.value = userName;
	}, [userName]);

	function GetEmail() {
		return (
			<div>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
						Email to reset password
						  </label>
					<input ref={emailInput} className={`${emailError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="username" type="text" placeholder="Email" />
					{ emailError || changeError ? (<p className="text-red-500 text-xs italic">{errorMsg}</p>) : null}
				</div>
				<div className="block flex items-center justify-between">
					<button onClick={onSubmitResetPassword} className="w-32 shadow inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						Reset
					  </button>
					<div className="block">
						<span onClick={() => pageChange("signin")} className="cursor-pointer block align-baseline font-bold text-sm text-indigo-600 hover:text-indigo-800">
							Switch to Sign in
					  </span>
					</div>
				</div>
			</div>
		)
	};

	function ChangePassword() {
		return (
			<div>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="verificationcode">
						Verification code sent to your email
						  </label>
					<input ref={codeInput} className={`${codeError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="verificationcode" type="text" placeholder="Verification code" />
				</div>
				<div className="mb-6">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
						New Password
						  </label>
					<input ref={passInput} className={`${passwordError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="password" type="password" placeholder="******************" />
				</div>
				<div className="mb-6">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
						Repeat New Password
						  </label>
					<input ref={passRepeatInput} className={`${passwordRepeatError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="password" type="password" placeholder="******************" />
					{codeError || passwordError || passwordRepeatError || changeError ? (<p className="text-red-500 text-xs italic">{errorMsg}</p>) : null}
				</div>
				<div className="block flex items-center justify-between">
					<button onClick={onSubmitChangePassword} className="w-32 shadow inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						Change
						  </button>
					<div className="block">
						<span onClick={() => pageChange("signin")} className="cursor-pointer block align-baseline font-bold text-sm text-indigo-600 hover:text-indigo-800">
							Switch to Sign in
						  </span>
					</div>
				</div>
			</div>
		)
	}

	const onSubmitResetPassword = () => {
		const emailConfirm = emailInput.current.value;
		let emailValidated = false;
		setEmailError(false);
		if (validateEmail(emailConfirm)) {
			emailValidated = true;
		} else {
			setEmailError(true);
			setErrorMsg("Email format is incorrect.");
		}
		if (emailValidated) {
			Auth.forgotPassword(emailConfirm).then(() => {
				setEmail(emailConfirm);
				setFirstStage(false);
			}).catch(err => {
				setChangeError(true);
				setErrorMsg(err.message);
			});
		}
	};

	const onSubmitChangePassword = () => {
		const code = codeInput.current.value;
		const password = passInput.current.value;
		const passwordRepeat = passRepeatInput.current.value;
		let passwordValidated = false;
		let passwordRepeatValidated = false;
		let codeValidated = false;

		setCodeError(false);
		setPasswordError(false);
		setPasswordRepeatError(false);
		setChangeError(false);

		if (validatePassword(password)) {
			passwordValidated = true;
		} else {
			setPasswordError(true);
			setErrorMsg("Please choose a password.");
		}
		if (validateRepeatPassword(password, passwordRepeat)) {
			passwordRepeatValidated = true;
		} else {
			setPasswordRepeatError(true);
			setErrorMsg("Passwords do not match.");
		}
		if (validateCode(code)) {
			codeValidated = true;
		} else {
			setCodeError(true);
			setErrorMsg("Please key in verification code.");
		}
		if (codeValidated && passwordValidated && passwordRepeatValidated) {
			Auth.forgotPasswordSubmit(email, code, password).then(() => {
				Auth.signIn(email, password).catch(err => {
					setChangeError(true);
					setErrorMsg(err.message);
				});
			}).catch(err => {
				setChangeError(true);
				setErrorMsg(err.message);
			});
		}
	};

	return (
		<div className="m-auto w-full max-w-xs">
			<form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
				{firstStage ? <GetEmail /> : <ChangePassword />}
			</form>
		</div >
	)
}

export default ForgetPassword;