import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SvgAdd from "../assets/pricing/svg/SvgAdd";
import SvgCheck from "../assets/pricing/svg/SvgCheck";
import { get } from "axios";

function Pricing() {
	const [signaled, setSignaled] = useState(false);
	const history = useHistory();
	const handleFree = () => history.push("/user/auth");
	const handleSignal = async () => {
		if (!signaled) {
			setSignaled(true);
			await get(`${process.env.REACT_APP_API_URL}/v1/api/signal-interest`);
		}
	};

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 ">
			<div className="border-t-8 border-gray-700 justify-self-center md:mr-2 md:justify-self-end mt-8 h-96 w-11/12 p-4 border-solid border-2 rounded-lg shadow-md transition duration-300 hover:shadow-lg">
				<h1 className="text-center text-3xl font-bold">Free</h1>
				<p className="mt-2 text-center">Share some links, gain some insights</p>
				<p className="mt-4 text-center font-semibold">$0 per month</p>
				<button onClick={handleFree} className="mt-4 w-full bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded">Start sharing links</button>
				<div className="block mx-auto w-11/12 grid grid-cols-1 mt-4">
					<span className="items-center"><SvgCheck stroke="green" fill="green" className="mr-3" />URL Shortening via Aelperlink</span>
					<span className="items-center"><SvgCheck stroke="green" fill="green" className="mr-3" />Measure number of Aelperlink clicks</span>
					<span className="items-center"><SvgCheck stroke="green" fill="green" className="mr-3" />Differentiate unique and repeated clicks</span>
					<span className="items-center"><SvgCheck stroke="green" fill="green" className="mr-3" />Geo distribution of clicks</span>
					<span className="items-center"><SvgCheck stroke="green" fill="green" className="mr-3" />Referer distribution of clicks</span>
					<span className="items-center"><SvgCheck stroke="green" fill="green" className="mr-3" />Time of day distribution of clicks</span>
				</div>
			</div>
			<div className="border-t-8 border-purple-500 justify-self-center md:ml-2 md:justify-self-start mt-8 h-96 w-11/12 p-4 border-solid border-2 rounded-lg shadow-md transition duration-300 hover:shadow-lg">
				<h1 className="text-center text-3xl font-bold">Professional</h1>
				<p className="mt-2 text-center">Gain deeper control over your links</p>
				<p className="mt-4 text-center font-semibold">Pricing to be announced</p>
				<button onClick={handleSignal} className={`${signaled ? "bg-gray-300" : "bg-purple-500 hover:bg-purple-700"} mt-4 w-full text-white font-bold py-2 px-4 rounded`}>{signaled ? "Interest Signaled" : "Signal interest"}{signaled ? (<SvgCheck stroke="green" fill="green" className="ml-3" />) : null}</button>
				<div className="block mx-auto w-11/12 grid grid-cols-1 mt-4">
					<span className="items-center"><SvgCheck stroke="green" fill="green" className="mr-3" />Everything in free</span>
					<span className="items-center"><SvgAdd stroke="green" fill="green" className="mr-3" />Updating Aelperlink target</span>
					<span className="items-center"><SvgAdd stroke="green" fill="green" className="mr-3" />Deleting Aelperlinks</span>
					<span className="items-center"><SvgAdd stroke="green" fill="green" className="mr-3" />Time between repeated clicks distribution</span>
				</div>
			</div>
		</div>
	);
}

export default Pricing;
