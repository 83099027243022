import React from "react";
import SharingBtn from "../shared/SharingBtn";
import InputFieldCopy from "../shared/InputFieldCopy";

function LinkInfoBox({ setSelectedModal, linkInfo, userKey }) {
	return (
		<div className="mt-10 p-4 border-solid border-2 rounded-lg mx-auto  shadow-md transition duration-300 hover:shadow-lg">
			<InputFieldCopy shortenLink={linkInfo.aelperLink} userKey={userKey} privateLink={true} />
			<div className="mt-4"></div>
			<SharingBtn shortenLink={linkInfo.aelperLink} userKey={userKey} privateLink={true} />
			<div className="mt-4"></div>
			<div className="flex flex-col">
				<p className="roboto">Original Link:&nbsp;&nbsp;</p>
				<a
					className="w-64 h-40 overflow-y-auto break-words lightBlue pr-6 hover:underline openSans"
					target="_blank"
					rel="noopener noreferrer"
					href={linkInfo.t}
				>
					{linkInfo.t}
				</a>
			</div>

			<button
				id="viewStaticsBtn"
				className=" text-white   focus:outline-none p-2 rounded-md roboto text-lg w-full mt-8 transition duration-300"
				onClick={() => setSelectedModal(linkInfo.lk)}
			>
				View Statistics
			</button>
		</div>
	);
}

export default LinkInfoBox;
