import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

function DoughnutChart({ chartLabel, dataLabels, chartData }) {
	const [data, setData] = useState({});
	
	useEffect(() => {
		setData({
			labels: dataLabels,
			datasets: [
				{
					label: chartLabel,
					data: chartData,
					backgroundColor: [
						"rgba(255, 99, 132, 0.2)",
						"rgba(54, 162, 235, 0.2)",
						"rgba(255, 206, 86, 0.2)",
						"rgba(75, 192, 192, 0.2)",
						"rgba(153, 102, 255, 0.2)",
					],
					borderColor: [
						"rgba(255, 99, 132, 1)",
						"rgba(54, 162, 235, 1)",
						"rgba(255, 206, 86, 1)",
						"rgba(75, 192, 192, 1)",
						"rgba(153, 102, 255, 1)",
					],
					borderWidth: 1,
				},
			],
		});
	}, [dataLabels, chartLabel, chartData]);

	return (
		<div className="max-w-md mx-auto">
			
			<Doughnut
				title={chartLabel}
				data={data}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					responsiveAnimationDuration: 1000,
					title: {
						display: true,
						text: chartLabel,
						fontSize: 20,
					},
					legend: {
						display: true,
						position: "right",
					},
				}}
			/>
		</div>
	);
}

export default DoughnutChart;
