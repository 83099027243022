import React, { useContext, useEffect } from "react";
import OriginalUrlForm from "../components/shared/OriginalUrlForm";
import LandingMessage from "../components/landing/LandingMessage";
import LandingGraphics from "../components/landing/LandingGraphics";
import LandingFeatures from "../components/landing/LandingFeatures";
import { ModalContext, AuthContext } from "../App"
import { useHistory } from "react-router-dom";

function LandingPage() {
	const history = useHistory();
	const { authed } = useContext(AuthContext);
	const { setSelectedModal, setShortenLink } = useContext(ModalContext);

	useEffect(() => {
		if (authed) {
			history.push("/user/dashboard")
		}
	}, [history, authed]);

	return (
		<div>
			<div className="highlightBox pt-8 md:pt-40 lg:pt-48 pb-16  lg:pb-56 md:pb-40 sm:pb-32">
				<LandingMessage />
				<OriginalUrlForm setSelectedModal={setSelectedModal} setShortenLink={setShortenLink} landingPage={true} />
			</div>
			<LandingFeatures />
			<LandingGraphics />
		</div>
	);
	// }
}

export default LandingPage;
