import * as React from "react";

function SvgCheck(props) {
	return (
		<svg height="12" viewBox="0 0 12 12" width="12"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g>
				<path transform="matrix(0.5,0,0,0.6,5.4,0.8)"
					d="M -2 15.1 c -0.2 0.2 -0.4 0.3 -0.7 0.3 s -0.5 -0.1 -0.7 -0.3 l -5.6 -5.6 c -0.6 -0.6 -0.6 -1.5 0 -2.1 l 0.7 -0.7 c 0.6 -0.6 1.5 -0.6 2.1 0 l 3.5 3.5 l 9.5 -9.5 c 0.6 -0.6 1.5 -0.6 2.1 0 l 0.7 0.7 c 0.6 0.6 0.6 1.5 0 2.1 z" />
			</g>
		</svg>
	);
}

export default SvgCheck;