/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_2Pyv6bsI3",
    "aws_user_pools_web_client_id": "4c74350ggnkgl1emev4df3e2sk",
    "oauth": {
        "domain": "aelper-backend-production.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://aelper.com",
        "redirectSignOut": "https://aelper.com",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
