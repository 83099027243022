import React, { useEffect, useState, useRef } from "react";
import "../../assets/shared/css/inputFieldCopy.css";

// import { base10to64 } from "../../helper/functions";

function InputFieldCopy({ shortenLink, userKey, privateLink }) {

	const [clicked, setClicked] = useState(false);
	const [inputFieldId, setInputFieldId] = useState("");
	const [copiedMessageStyle, setCopiedMessageStyle] = useState("");
	const [aelperLink, setAelperLink] = useState("");
	const textAreaRef = useRef(null);

	function clickToCopy(e) {
		const link = textAreaRef.current.value;
		const copyLink = `https://${link}`;
		textAreaRef.current.value = copyLink;
		textAreaRef.current.select();
		document.execCommand("copy");
		textAreaRef.current.value = link;
		setClicked(true);
	}

	useEffect(() => {
		setInputFieldId(privateLink ? "originalUrlForm" : "aelperLink");
		setCopiedMessageStyle(clicked ? "🎉Copied!" : "Copy");
		const compactAelperLink = shortenLink.replace("https://", "")
		if (privateLink) {
			if (userKey) {
				setAelperLink(compactAelperLink);
			} else {
				setAelperLink(`Loading...`);
			}
		} else {
			setAelperLink(compactAelperLink);
		}
	}, [privateLink, clicked, shortenLink, userKey]);

	return (
		<div>
			<button
				id={inputFieldId}
				className="cursor-pointer  bg-gray-800 text-white  p-2  roboto  text-left rounded-md h-16"
				onClick={clickToCopy}
			>
				<input
					// id="shortenUrl"
					id={shortenLink}
					ref={textAreaRef}
					value={aelperLink}
					className="bg-gray-800 inline outline-none active:outline-none focus:outline-none cursor-pointer openSans text-sm hover:underline"
					readOnly="readonly"

				/>
				<span
					className="ml-4 text-gray-400 roboto"
					role="img"
					aria-label="copied-emoji"
				>
					{copiedMessageStyle}
				</span>
			</button>
		</div>
	);
}

export default InputFieldCopy;
