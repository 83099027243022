import React, { useRef, useContext } from "react";
import { validateUrl } from "../../helper/functions";
import { motion } from "framer-motion";
import { post } from "axios";
import { AuthContext } from "../../App";
import { isFirefox } from "react-device-detect";

const inputContainer = {
	hidden: {
		scale: 0,
		opacity: 0,
	},
	visible: {
		scale: 1,
		opacity: 1,
		transition: {
			type: "spring",
			stiffness: 260,
			damping: 100,
		},
	},
};
function OriginalUrlForm({
	setSelectedModal,
	setShortenLink,
	newLinkKey,
	setRefresh,
	refresh,
	landingPage,
}) {
	const { authed } = useContext(AuthContext);
	const rawLink = useRef(null);
	const placeholderText = isFirefox ? "Paste link here" : "Link copied? Just click Shorten";

	function fetchUserKey() {
		if (rawLink.current.value === "") {
			if (!isFirefox) {
				navigator.permissions
					.query({ name: "clipboard-read" })
					.then((result) => {
						// If permission to read the clipboard is granted or if the user will
						// be prompted to allow it, we proceed.

						if (
							result.state === "granted" ||
							result.state === "prompt"
						) {
							navigator.clipboard.readText().then((data) => {
								rawLink.current.value = data;
								// wait for data to be retrieved
								const userLink = validateUrl(rawLink.current.value);
								post(`${process.env.REACT_APP_API_URL}/v1/api/create-link`, {
									t: userLink
								}, {
									headers: {
										Authorization: localStorage.getItem("id_token")
									}
								}).then(() => {
									setRefresh(!refresh);
								}).catch(err => {
									alert("Unable to create Aelper disallowed target link.")
								});
								rawLink.current.value = "";
							});
						}
					});
			}
		} else {
			const userLink = validateUrl(rawLink.current.value);
			post(`${process.env.REACT_APP_API_URL}/v1/api/create-link`, {
				"t": userLink
			}, {
				headers: {
					Authorization: localStorage.getItem("id_token")
				}
			}).then(() => {
				setRefresh(!refresh);
			}).catch(err => {
				alert("Unable to create Aelper disallowed target link.")
			});
			rawLink.current.value = "";
		}
	}
	function fetchPublicKey() {
		// to handle public link creation


		if (authed) {
			alert(
				"☺️ This is for guest users. Please create an aelper link on your dashboard page."
			);
		} else {
			if (rawLink.current.value === "") {
				if (!isFirefox) {
					navigator.permissions
						.query({ name: "clipboard-read" })
						.then((result) => {
							// If permission to read the clipboard is granted or if the user will
							// be prompted to allow it, we proceed.

							if (
								result.state === "granted" ||
								result.state === "prompt"
							) {
								navigator.clipboard.readText().then((data) => {
									rawLink.current.value = data;
									// wait for data to be retrieved
									const userLink = validateUrl(
										rawLink.current.value
									);

									// eslint-disable-next-line no-use-before-define
									post(`${process.env.REACT_APP_API_URL}/v1/api/create-pub-link`, {
										"t": userLink
									})
										.then(
											(res) => {
												setShortenLink(res.data.result.aelperLink);
												setSelectedModal("publicLink");
											}
										).catch(err => {
											alert("Unable to create Aelper disallowed target link.")
										});
									rawLink.current.value = "";
								});
							}
						});
				}
			} else {
				const userLink = validateUrl(rawLink.current.value);

				// eslint-disable-next-line no-use-before-define
				post(`${process.env.REACT_APP_API_URL}/v1/api/create-pub-link`, {
					"t": userLink
				})
					.then((res) => {
						setShortenLink(res.data.result.aelperLink);
						setSelectedModal("publicLink");
					}).catch(err => {
						alert("Unable to create Aelper disallowed target link.")
					});
				rawLink.current.value = "";
			}
		}
	}
	const fetchName =
		authed && !landingPage ? fetchUserKey : fetchPublicKey;

	return (
		<motion.div
			className="grid mt-2   place-items-center  "
			variants={inputContainer}
			initial="hidden"
			animate="visible"
		>
			<div className="grid place-items-center relative w-11/12 sm:w-3/4 md:max-w-md lg:max-w-lg">
				<button
					onClick={fetchName}
					className="originalInputLogo absolute text-white h-full rounded-r-full p-2 right-0 transition duration-300 focus:outline-none"
					type="submit"
				>
					Shorten
				</button>
				<input
					ref={rawLink}
					type="text"
					placeholder={placeholderText}
					className="focus:outline-none hover:shadow-lg focus:shadow-lg shadow appearance-none border rounded  py-2  pl-6 pr-20 text-gray-700 leading-tight w-full  rounded-full h-12 transition duration-300"
				></input>
			</div>
		</motion.div>
	);
}

export default OriginalUrlForm;
