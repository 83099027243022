import React, { useState } from "react";
import {
	EmailIcon,
	FacebookIcon,
	LinkedinIcon,
	TelegramIcon,
	TwitterIcon,
	WhatsappIcon,
} from "react-share";
import { motion } from "framer-motion";
// import { base10to64 } from "../../helper/functions";
import "../../assets/userDashboard/css/userDashboard.css";

const variants = {
	open: { visibility: "visible", x: 0 },
	closed: { visibility: "hidden", x: "-100%", height: "0" },
};
function SharingBtn({userKey, shortenLink, idConverted, privateLink}) {

	const [isOpen, setIsOpen] = useState(false);
	let aelperLink = shortenLink;

	function shareBtnClick(aelperLink){
		if(navigator.share){
			navigator.share({
				title: "Interesting",
				text: "✅ Check this out.",
				url: aelperLink
			}).then(() => {
			}).catch(console.error);
		} else{
			setIsOpen(!isOpen);
		}
	}

	return (
		<div>
			<svg
				id="shareBtn"
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-share rounded-full p-1 cursor-pointer"
				onClick={() => shareBtnClick(aelperLink)}
				width="32"
				height="32"
				viewBox="0 0 24 24"
				strokeWidth="1.5"
				stroke="#ffffff"
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<circle cx="6" cy="12" r="3" />
				<circle cx="18" cy="6" r="3" />
				<circle cx="18" cy="18" r="3" />
				<line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />
				<line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />
			</svg>
			<motion.div
				animate={isOpen ? "open" : "closed"}
				variants={variants}
			>
				<a href={"mailto:subject=Aelper Link&body=" + aelperLink}>
					<EmailIcon size={32} round={true} />
				</a>
				<a
					href={"https://www.facebook.com/sharer/sharer.php?u="+aelperLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<FacebookIcon size={32} round={true} />
				</a>
				<a
					href={"https://www.linkedin.com/shareArticle?&url=" + aelperLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<LinkedinIcon size={32} round={true} />
				</a>
				<a
					href={"https://t.me/share/url?url=" + aelperLink + "&text=Aelper Links"}
					target="_blank"
					rel="noopener noreferrer"
				>
					<TelegramIcon size={32} round={true} />
				</a>
				<a
					href={"http://twitter.com/share?url=" + aelperLink + "&text=Proudly made with Aelper&hashtags=aelper,links,analytics"}
					target="_blank"
					rel="noopener noreferrer"
				>
					<TwitterIcon size={32} round={true} />
				</a>
				<a
					href={"https://api.whatsapp.com/send?&text=" + aelperLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<WhatsappIcon size={32} round={true} />
				</a>
			</motion.div>
		</div>
	);
}

export default SharingBtn;
