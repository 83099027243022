import React from "react";

function PrivacyPolicy() {
	return (
		<div className="w-10/12 mx-auto">
			<h2 className="roboto text-3xl mt-4 text-center  sm:text-4xl md:text-5xl lg:text-6xl">
				Our Privacy Policy
			</h2>
			<h4 className="openSans mt-8 mb-6 font-bold text-justify text-lg md:text-xl lg:text-2xl">
				TLDR: the location that is analysed and shown is only on a
				national level and does not involve IP addresses.
			</h4>
			<p className="openSans text-justify mb-3 leading-relaxed md:text-lg lg:text-xl ">
				Aelper recognises a click’s country of origin by identifying the
				country code of the browser which the person who clicked on the
				link is using (i.e. request header).
			</p>
			<p className="openSans text-justify mb-3 leading-relaxed md:text-lg lg:text-xl">
				This is readily available information and is standard industry
				practice. As we do not analyse, collect or reveal specific IP
				addresses or locations, the anonymity of those who click on
				Aelperlinks is protected.
			</p>
		</div>
	);
}

export default PrivacyPolicy;
