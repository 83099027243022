function base10to64(base10) {
		const order = "0123456789-abcdefghijklmnopqrstuvwxyz_ABCDEFGHIJKLMNOPQRSTUVWXYZ"
		let str = ""
		let num = base10
		if (num) {
			while (num) {
				const d = num % 64;
				str = order[d] + str;
				num = Math.floor(num / 64);
			}
			return str
		} else {
			return "0"
		}
	}
	
function validateUrl(value){
		const result = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
		value
		);
		if (!result){
			return `https://${value}`
		} else {
			return value
		}
}

function calculateDays(unixTime){
	// const ageInUnix = Date.now() - unixTime 
	const age = Math.round(unixTime / (60*60*24*10)) / 100
	return age
}

function sortClicks(rawObject){
	
		let sortable = [];

		for (const attr in rawObject) {
			sortable.push([attr, rawObject[attr]]);
		}

		sortable.sort(function(a, b) {
			return b[1] - a[1];
		});
	if (Object.keys(rawObject).length >4){
		let top4Attr = sortable.slice(0, 4)
		let otherAttr = sortable.slice(4, sortable.length)
		
		let otherClicks = 0
		for(const attr in otherAttr){
			otherClicks += otherAttr[attr][1]
		}

		let fiveAttr = top4Attr.concat([["Others", otherClicks]])
		fiveAttr.sort(function(a, b) {
			return b[1] - a[1];
		});
		return fiveAttr
	}else {
		sortable.sort(function(a, b) {
			return b[1] - a[1];
		});
		return sortable
	}
	
	

}

function cleanGivenUrl(rawGivenUrl){
	let cleanedUrlArray = rawGivenUrl.match(/\bhttps?:\/\/\S+/gi);

	if(cleanedUrlArray.length > 1){
		return 2
	} else if(cleanedUrlArray.length === 0){
		return 0
	} else{
		return cleanedUrlArray[0]
	}
}
    
const validateCode = (code) => {
	return (code && code.length > 0);
};

const validateEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

const validatePassword = (pass) => {
	return (pass && pass.length > 0);
};

const validateRepeatPassword = (pass, pass2) => {
	return pass === pass2;
};

export { validateCode, validateEmail, validatePassword, validateRepeatPassword, base10to64, validateUrl, calculateDays, sortClicks, cleanGivenUrl};