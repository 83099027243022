import React from "react";

function NotFound() {
	return (
		<div className="grid place-items-center  grid-cols-1 lg:grid-cols-2 lg:py-40 gap-y-12 mt-6">
			<div className="w-4/5">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/aelper-1.appspot.com/o/public%2FsiteGraphics%2FNotFound.svg?alt=media&token=1f7fdc94-281d-4baa-a97b-340fd9130ffd"
                    alt=""
                    className=""
				/>
			</div>

			<div className="text-center w-4/5">
				<h1 className="roboto sitePurple font-black text-6xl">OOPS!</h1>
				<h3 className="openSans font-black text-xl lg:text-2xl">We can't seem to find the page you're looking for.</h3>

				<button
					className="text-white text-xl font-bold   roboto originalInputLogo px-8 py-4 mt-8 rounded-lg lg:mt-24"
					onClick={() =>
						window.location.replace(`${window.location.origin}`)
					}
				>
					Back to Home Page
				</button>
			</div>
		</div>
	);
}

export default NotFound;
