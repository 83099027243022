import React from "react";
import LoginOptions from "../components/userAuth/LoginOptions";

import "../assets/userAuth/css/userAuth.css";

function UserAuthPage() {
	return (
		<div>
			<LoginOptions />
		</div>
	);
}

export default UserAuthPage;
