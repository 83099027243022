import * as React from "react";
import "../install.css";

function SvgInstall(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			stroke="currentColor"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
			{...props}
		>

			<path d="m2 22h20z" />
			<path d="m12 2v16z" />
			<path d="m12 18-6-6z" />
			<path d="m12 18 6-6z" />
		</svg>
	);
}

export default SvgInstall;