import * as React from "react";

function SvgUserGuide(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			fill="none"
			stroke="currentColor"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
			className="book-open_svg__feather book-open_svg__feather-book-open"
			{...props}
		>
			<path d="M2 3h6a4 4 0 014 4v14a3 3 0 00-3-3H2zM22 3h-6a4 4 0 00-4 4v14a3 3 0 013-3h7z" />
		</svg>
	);
}

export default SvgUserGuide;
