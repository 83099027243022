import * as React from "react";

function SvgAdd(props) {
	return (
		<svg height="12pt" viewBox="0 0 12 12" width="12pt" xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
    <path
        d="M 7.7 4.8 h -2.2 c -0.1 0 -0.1 -0.1 -0.1 -0.1 v -2.5 c 0 -0.4 -0.3 -0.7 -0.6 -0.7 s -0.6 0.3 -0.6 0.7 v 2.5 c 0 0.1 -0.1 0.1 -0.1 0.1 h -2.2 c -0.3 0 -0.6 0.3 -0.6 0.7 s 0.3 0.7 0.6 0.7 h 2.2 c 0.1 0 0.1 0.1 0.1 0.1 v 2.5 c 0 0.4 0.3 0.7 0.6 0.7 s 0.6 -0.3 0.6 -0.7 v -2.5 c 0 -0.1 0.1 -0.1 0.1 -0.1 h 2.2 c 0.3 0 0.6 -0.3 0.6 -0.7 s -0.3 -0.7 -0.6 -0.7 z m 0 0" />
		</svg>
	);
}

export default SvgAdd;