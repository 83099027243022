import React, { useRef, useState } from "react";
import { Auth } from 'aws-amplify';
import { validateEmail, validatePassword } from "../../helper/functions"
import GoogleIcon from "../../assets/userAuth/svg/google-icon.svg";
import FacebookIcon from "../../assets/userAuth/svg/facebook-icon.svg";

function Signin({ pageChange, setUserName, setPassword }) {

	const emailInput = useRef("");
	const passInput = useRef("");
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [signinError, setSigninError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const onSubmitEmailLogin = () => {
		const email = emailInput.current.value;
		const password = passInput.current.value;
		let emailValidated = false;
		let passwordValidated = false;
		setEmailError(false);
		setPasswordError(false);
		setSigninError(false);
		if (validateEmail(email)) {
			emailValidated = true;
		} else {
			setEmailError(true);
			setErrorMsg("Email format is incorrect.");
		}
		if (validatePassword(password)) {
			passwordValidated = true;
		} else {
			setPasswordError(true);
			setErrorMsg("Please choose a password.");
		}
		if (emailValidated && passwordValidated) {
			Auth.signIn(email, password).catch(err => {
				setSigninError(true)
				setErrorMsg(err.message)
				if (err.code === "UserNotConfirmedException") {
					setPassword(password);
					setUserName(email);
					pageChange("verify");
				}
			});
		}
	};

	const handleForgetPassword = () => {
		setUserName(emailInput.current.value);
		pageChange("forget");
	};

	const onSubmitGoogleLogin = () => {
		Auth.federatedSignIn({ provider: 'Google' });
	};

	const onSubmitFacebookLogin = () => {
		Auth.federatedSignIn({provider: 'Facebook'});
	};

	return (
		<div className="m-auto w-full max-w-xs">
			<form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
				<h1 className="mb-4 text-gray-700 text-center roboto font-bold text-lg">Social Sign In:</h1>
				<div className="mb-6 flex items-center justify-between">
					<button onClick={onSubmitGoogleLogin} className="shadow inline-flex w-full text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						<img alt="Google" src={GoogleIcon} width="30" className="justify-start fill-current w-4 h-4 mr-2" />
						<span className="w-full text-center">Google Sign In</span>
					</button>
				</div>
				<div className="mb-6 flex items-center justify-between">
					<button onClick={onSubmitFacebookLogin} className="shadow inline-flex w-full text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						<img alt="Facebook" src={FacebookIcon} width="30" className="justify-start fill-current w-4 h-4 mr-2" />
						<span className="w-full text-center">Facebook Sign In</span>
					</button>
				</div>
				<hr className="mb-6" />
				<h1 className="text-gray-700 text-center roboto font-bold text-lg">Email Sign In:</h1>
				<div className="mb-4">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
						Email
      				</label>
					<input ref={emailInput} className={`${emailError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="username" type="text" placeholder="Email" />
				</div>
				<div className="mb-6">
					<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
						Password
      				</label>
					<input ref={passInput} className={`${passwordError ? "border-red-500" : ""} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="password" type="password" placeholder="******************" />
					{passwordError || emailError || signinError ? (<p className="text-red-500 text-xs italic">{errorMsg}</p>) : null}
				</div>
				<div className="block flex items-center justify-between">
					<button onClick={onSubmitEmailLogin} className="w-32 shadow inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-600 mt-4 lg:mt-0" type="button">
						Sign In
      				</button>
					<div className="block">
						<span onClick={handleForgetPassword} className="cursor-pointer block align-baseline font-bold text-sm text-indigo-600 hover:text-indigo-800">
							Forgot Password?
      					</span>
						<span onClick={() => pageChange("signup")} className="cursor-pointer block align-baseline font-bold text-sm text-indigo-600 hover:text-indigo-800">
							Switch to Sign up
      					</span>
					</div>
				</div>
			</form>
		</div >
	)
}

export default Signin;