import React from "react";
// import { Link } from "react-router-dom";
// svg imports
// import SvgSharePurpose from "../../assets/landingPage/svg/SvgSharePurpose.svg";
import SvgShareFaster from "../../assets/landingPage/svg/SvgShareFaster.svg";
import SvgShareSimpler from "../../assets/landingPage/svg/SvgShareSimpler.svg";
import SvgShareSafer from "../../assets/landingPage/svg/SvgShareSafer.svg";

function LandingGraphics() {
	return (
		<div className="flex flex-col items-center">
			{/* <div className="infoBoxOdd py-12 lg:py-16 md:flex md:justify-center">
				<img
					src={SvgSharePurpose}
					alt=""
					className="w-56 sm:w-64 image-size-lg block mx-auto md:mx-16 xl:mx-40"
				/>
				<div className="w-4/5 mt-2 md:my-auto max-w-xs mx-auto md:mx-16 lg:max-w-lg xl:mx-40">
					<h3 className="mb-2 md:mb-4 xl:mb-6 roboto font-bold text-lg md:text-2xl lg:text-3xl">
						Share with a purpose
					</h3>
					<h4 className="openSans text-justify lg:text-lg">
						Finally understand what your audience likes through
						Aelper's total clicks, unique clicks and geolocation
						data.
					</h4>
				</div>
			</div> */}

			<div className="infoBoxOdd py-12 lg:py-16 md:flex md:justify-center">
				<img
					src={SvgShareFaster}
					alt=""
					className="w-56  sm:w-64 image-size-lg  md:mt-0 mx-auto md:mx-16 xl:mx-40"
				/>
				<div className="w-4/5 mt-2 md:my-auto max-w-xs mx-auto md:mx-16 lg:max-w-lg xl:mx-40">
					<h3 className="mb-2 md:mb-4 xl:mb-6 roboto font-bold text-lg md:text-2xl lg:text-3xl">
						Share faster
					</h3>
					<h4 className="openSans text-justify lg:text-lg">
						Enjoy an easier workflow with Aelper's mobile-first
						design and consolidated dashboard.
					</h4>
				</div>
			</div>

			<div className="infoBoxEven py-12 lg:py-16 md:flex md:justify-center">
				<img
					src={SvgShareSimpler}
					alt=""
					className="w-56  sm:w-64 image-size-lg md:mt-0 mx-auto md:mx-16 xl:mx-40"
				/>
				<div className="w-4/5 mt-2 md:my-auto max-w-xs mx-auto md:mx-16 lg:max-w-lg xl:mx-40 order-first">
					<h3 className="mb-2 md:mb-4 xl:mb-6 roboto font-bold text-lg md:text-2xl lg:text-3xl">
						Share simpler
					</h3>
					<h4 className="openSans text-justify lg:text-lg">
						Get shorter URLs as a bonus when you convert them into
						Aelperlinks.
					</h4>
				</div>
			</div>

			<div className="infoBoxOdd py-12 lg:py-16 md:flex md:justify-center">
				<img
					src={SvgShareSafer}
					alt=""
					className="w-56  sm:w-64 image-size-lg  md:mt-0 mx-auto md:mx-16 xl:mx-40"
				/>
				<div className="w-4/5 mt-2 md:my-auto max-w-xs mx-auto md:mx-16 lg:max-w-lg xl:mx-40">
					<h3 className="mb-2 md:mb-4 xl:mb-6 roboto font-bold text-lg md:text-2xl lg:text-3xl">
						Share safely
					</h3>
					<h4 className="openSans text-justify lg:text-lg">
						At Aelper, we value your privacy. That’s why we don’t
						use IP addresses to pinpoint exact locations. Our
						geolocation methods only show countries, and nothing
						more. Rest assured your anonymity is safe with us.
					</h4>
					{/* <Link to="/privacyPolicy">
						<p className="openSans mt-3  text-sm underline inline-block text-gray-600 hover:text-gray-800 md:mt-4 lg:mt-5">Learn more about how we use data</p>
					</Link> */}
				</div>
			</div>
		</div>
	);
}

export default LandingGraphics;
