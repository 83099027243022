import React, { useContext, useState } from "react";
import { AuthContext } from "../../App"
import { Redirect, Route } from "react-router-dom";
// import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import Signup from "./Signup";
import Signin from "./Signin";
import VerifyEmail from "./VerifyEmail";
import ForgetPassword from "./ForgetPassword";

function LoginOptions() {
	const { authed } = useContext(AuthContext);
	const [page, setPage] = useState("signin");
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");

	return (
		<Route
			render={() => {
				if (authed) {
					return (
						<Redirect
							to={{
								pathname: "/user/dashboard",
							}}
						/>
					);
				} else if (page === "signin") {
					return (
						<div>
							<Signin pageChange={setPage} setUserName={setUserName} setPassword={setPassword} />
						</div>
					);
				} else if (page === "signup") {
					return (
						<div>
							<Signup pageChange={setPage} setUserName={setUserName} setPassword={setPassword} />
						</div>
					);
				} else if (page === "verify") {
					return (
						<div>
							<VerifyEmail pageChange={setPage} userName={userName} password={password} />
						</div>
					);
				} else if (page === "forget") {
					return (
						<div>
							<ForgetPassword pageChange={setPage} userName={userName} />
						</div>
					);
				} else {
					return <div></div>
				}
			}}
		/>
	);
}

export default LoginOptions;
